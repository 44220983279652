/* HomePage.css */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.home-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 80vh; /* Ensure the page takes at least full viewport height */
}

.home-background-image {
  position: relative; /* Position it relatively */
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-image: url('background.png'); /* Correct background image */
  background-size: cover;
  background-position: center;
  z-index: 1; /* Lower z-index to stay behind other elements */
}

.home-text-container {
  position: relative; /* Position it relatively */
  z-index: 2; /* Higher z-index to stay on top of background */
  text-align: center;
  color: #fff;
  margin-top: 60vh; /* Center text vertically */
}

.askida-text {
  font-size: 4rem; /* Adjust font size as needed */
  margin: 0;
}

.home-subtext {
  font-size: 1.5rem; /* Adjust font size as needed */
  margin: 0;
}

.discover-button {
  background-color: transparent; /* Transparent background */
  color: #fff; /* White text */
  border: 2px solid #fff; /* White outline */
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.discover-button:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Light transparent white on hover */
}

@media (max-width: 768px) {
  .askida-text {
    font-size: 3rem; /* Adjust font size for smaller screens */
  }
  .subtext {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }
  .discover-button {
    padding: 8px 16px; /* Adjust padding for smaller screens */
    margin-top: 15px; /* Adjust margin for smaller screens */
  }
  .home-text-container {
    margin-top: 60vh; /* Adjust vertical margin for smaller screens */
  }
}

@media (max-width: 480px) {
  .askida-text {
    font-size: 2rem; /* Adjust font size for very small screens */
  }
  .subtext {
    font-size: 1.5rem; /* Adjust font size for very small screens */
  }
  .discover-button {
    padding: 6px 12px; /* Adjust padding for very small screens */
    margin-top: 10px; /* Adjust margin for very small screens */
  }
}