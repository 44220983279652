.footer {
  background-color: #1f2937; /* Dark background */
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 20px 50px;
  font-family: 'Arial', sans-serif;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 100px;
  padding-right: 100px;
}

.footer-left {
  display: flex;
  flex-direction: column;
  padding-right: 40px;
}

.logos {
  margin-bottom: 15px;
}

.asunatech-logo {
  width: auto;
  height: 48px;
  margin-right: 15px;
  margin-bottom: 20px;
}

.askida_icon {
  width: 40px;
  height: auto;
  margin-bottom: 20px;
}

.payment-logos {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.payment-logo {
  width: 500px;
  height: auto;
  cursor: pointer; /* Add cursor pointer */
}

.store-icon {
  width: 150px; /* Adjust width as needed */
  height: auto;
  margin-left: 15px; /* Add margin for spacing */
  cursor: pointer; /* Add cursor pointer */
}

.footer-right {
  display: flex;
  gap: 80px;
}

.footer-column h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 8px;
}

.footer-column ul li a {
  color: #d1d5db;
  text-decoration: none;
}

.footer-column ul li a:hover {
  color: #9ca3af;
}

.footer-bottom {
  background-color: #1f2937; /* Dark background */
  padding-bottom: 10px;
  text-align: center;
  font-size: 1rem;
  color: aliceblue;
}

/* Responsive styles */
@media (max-width: 768px) {
  .footer {
    padding: 20px;
  }

  .footer-left {
    align-items: center;
    padding-left: 20px;
  }

  .logos {
    flex-direction: column;
    align-items: center;
  }

  .asunatech-logo,
  .askida_icon {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .payment-logos {
    flex-direction: column;
    align-items: center;
  }

  .store-icon {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .footer-right {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer-column {
    align-self: center;
  }

  .footer-column ul {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .footer {
    flex-direction: column;
  }

  .footer-column h4 {
    text-align: center;
    align-self: center;
  }
  .footer-left {
    align-items: center;
  }

  .logos {
    flex-direction: column;
    align-items: center;
  }

  .asunatech-logo {
    margin: 0 10px 10px 0;
    height: 30px;
  }

  .payment-logos {
    flex-direction: column;
    align-items: center;
  }

  .store-icon {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .footer-right {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer-column {
    align-items: center;
  }

  .footer-column ul {
    text-align: center;
  }
}

