/* Navbar.css */
.navbar {
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px; /* Reduce padding for better fit */
  background-color: transparent; /* Transparent background */
  z-index: 10; /* Higher z-index to stay on top of other content */
  color: #fff; /* Text color */
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box; /* Ensure padding does not affect width */
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}
.nav-links li {
  display: inline-block;
}

.nav-links li a {
  text-decoration: none;
  color: #fff;
  padding: 10px;
  transition: color 0.3s ease, background-color 0.3s ease; /* Add transition for smooth hover effect */
}

.nav-links li a:hover {
  color: #FF7526; /* Hover text color */
  background-color: rgba(255, 255, 255, 0.1); /* Add a slight background color on hover */
}


.download-button {
  background-color: #fff; /* White background */
  color: #FF7526; /* Orange text */
  border: 2px solid #FF7526; /* Orange outline */
  border-radius: 5px;
  padding: 10px 15px; /* Adjust padding to fit better */
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.download-button:hover {
  background-color: #FF7526; /* Orange background on hover */
  color: #fff; /* White text on hover */
}

@media (max-width: 768px) {
  /* Responsive styling for smaller screens */
  .navbar {
      flex-direction: column;
      align-items: center; /* Center the navbar items */
  }

  .nav-links {
      flex-direction: row; /* Make the list items in a single row */
      gap: 20px; /* Increase the gap between items */
      justify-content: center; /* Center the list items */
      padding: 10px 0; /* Add padding for better spacing */
  }

  .nav-links li {
      width: auto; /* Adjust width to auto */
      text-align: center;
  }

  .download-button {
      width: auto; /* Adjust width to auto */
      text-align: center;
      margin-top: 10px;
  }
}