/* frontend/src/pages/PaymentStatusPage.css */
.payment-status-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .status-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 160px 20px 160px 20px;
  }
  
  .status-message {
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .status-message.success {
    background-color: #e6ffed;
    border: 1px solid #b2f5ea;
  }
  
  .status-message.failure {
    background-color: #ffe6e6;
    border: 1px solid #f5b2b2;
  }
  
  .status-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }
  
  .status-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .status-description {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .status-button {
    background-color: #FF6F1E;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .status-button:hover {
    background-color: #E65B12;
  }