.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto; /* Centers the content on the page */
  }
  
  .content {
    width: 100%;
    max-width: 1000px; /* Fixed width for content */
    padding: 20px;
    background-color: #f9f9f9; /* Light background for contrast */
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: 100px 0 100px 0;
  }
  
  .section {
    margin-bottom: 30px;
  }
  
  .section h2 {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .section p {
    font-size: 1.1em;
    color: #666;
    line-height: 1.6;
  }

  .footerContainer {
    width: 100%;
    background-color: #1f2937; /* Full-width background color */
  }
  
  .footerContent {
    max-width: 1200px; /* Constrain the width of the footer content */
    margin: 0 auto; /* Center the footer content */
    padding: 10px 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbarContainer {
    width: 100%;
    background-color: #1f2937; /* Full-width background color */
  }
  
  .customNavbar {
    max-width: 1200px; /* Constrain the width of the navbar content */
    margin: 0 auto; /* Center the navbar content */
    padding: 10px 20px;
    color: white;
    display: flex;
    align-items: center;
  }
  
  
  