/* Landing.css */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.landing-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 80vh; /* Ensure the page takes at least full viewport height */
}

.landing-background-image {
    position: relative; /* Position it relatively */
    width: 100%;
    height: 100vh; /* Full viewport height */
    background-image: url('landing_bg.png');
    background-size: cover;
    background-position: center;
    z-index: 1; /* Lower z-index to stay behind other elements */
}

.scroll-down-icon,
.landing-logo,
.landing-subtext {
    transition: width 0.3s ease, height 0.3s ease, font-size 0.3s ease;
}

.scroll-down-icon {
    width: 3vw; /* Default size for large screens */
    height: auto;
    flex-shrink: 0;
    margin-top: 20px;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}

.scroll-down-icon:hover {
    opacity: 0.6; /* Slightly reduce opacity on hover */
}

.landing-text-container {
    position: absolute; /* Change to absolute positioning */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for centering */
    z-index: 2; /* Higher z-index to stay on top of background */
    text-align: center;
    color: #fff;
}

.landing-subtext {
    font-size: 2.6vw; /* Increase font size */
    margin: 0;
    color: #FFF;
    font-family: 'Mont Serrat Alternates', sans-serif;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    padding-top: 5px;
}

.landing-logo {
    width: 40vw; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
}


/* Medium-sized screens */
@media (max-width: 992px) {
    .scroll-down-icon {
        width: 5vw; /* Increase width for medium-sized screens */
    }
}

/* Small-sized screens */
@media (max-width: 768px) {
    .landing-logo {
        width: 50vw; /* Adjust the width as needed */
        height: auto; /* Maintain aspect ratio */
    }
    .landing-subtext {
        font-size: 3.5vw; /* Adjust font size for very small screens */
    }
    .scroll-down-icon {
        width: 7vw; /* Further increase width for smaller screens */
    }
}

/* Extra small-sized screens */
@media (max-width: 480px) {
    .landing-logo {
        width: 60vw; /* Adjust the width as needed */
        height: auto; /* Maintain aspect ratio */
    }
    .landing-subtext {
        font-size: 4vw; /* Adjust font size for very small screens */
    }
    .scroll-down-icon {
        width: 10vw; /* Maximize width for very small screens */
        position: absolute;
        top: calc(50% + 100px); /* Position it below the landing text container */
        left: 50%;
        transform: translate(-50%, 0); /* Center horizontally */
        bottom: auto;
    }
}